import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from 'components/Layout'
import { DateTime } from 'luxon'

const Element = styled.div``
const Title = styled.h1`
  color: #ba2c73;
`
const Date = styled.div`
  margin-bottom: 1rem;
`
const Location = styled.div`
  margin-bottom: 1rem;
`

const Event = ({ data }) => {
  const { event } = data
  return (
    <Layout>
      <Element>
        <Title>{event.name.text}</Title>
        <Date>
          Datum:{' '}
          {DateTime.fromISO(event.start.local)
            .setLocale('de')
            .toLocaleString(DateTime.DATETIME_MED)}
        </Date>
        {/*<Location>Veranstaltungsort: {event.location.name}</Location>*/}
        <Location>Veranstaltungsort: Julius Klengel Akademie</Location>
        <div
          dangerouslySetInnerHTML={{
            __html: event.description.html,
          }}
        />
      </Element>
    </Layout>
  )
}

export const eventQuery = graphql`
  query events($id: String!) {
    event: eventbriteEvents(id: { eq: $id }) {
      id
      name {
        text
      }
      start {
        local
      }
      description {
        html
      }
    }
  }
`

export default Event
